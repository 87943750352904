.container {
  background-color: whitesmoke;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 80rem;
  border: solid 4px rgba(226, 20, 234, 0.4);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: 500;
  font-size: large;
}

.month {
  text-align: right;
  font-style: italic;
  opacity: 0.7;
}

.container h2 {
  color: #0a7561;
  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
